import React, { useEffect } from 'react';
import Particles from "react-tsparticles";
import './App.css';
import darkParticlesOptions from "./darkparticles.json";
import { FaTwitter, FaDiscord, FaWatchmanMonitoring } from "react-icons/fa";
import Countdown from 'react-countdown';
import Image from 'react-graceful-image';
// import { signERC2612Permit } from 'eth-permit';
import {ethers, utils, formatUnits, commify} from "ethers";
import {useEthers, useTokenBalance, useContractFunction, shortenAddress} from "@usedapp/core";
import {ChainId, useContractCalls} from '@usedapp/core';
import {BigNumber} from "@ethersproject/bignumber";
import {Contract} from '@ethersproject/contracts';
import { Interface } from '@ethersproject/abi';
import CountUp from 'react-countup';
import { NFTABI } from './NFTABI';
import { TOKENABI } from './TOKENABI';
import { ButtonGroup } from 'react-bootstrap';
import PacmanLoader from 'react-spinners/PacmanLoader';

const testMode = false;

function App() {

    //UI Stuff
    const stopTime = testMode ? '2022-01-27T04:07:45' : '2022-01-27T17:00:00'; //2022-01-27T17:00:00
    var targetTime = new Date(stopTime);
    targetTime.setMinutes(targetTime.getMinutes() - targetTime.getTimezoneOffset());
    const [showTargetTime, setShowTargetTime] = React.useState(targetTime >= Date.now());
    const {chainId, activateBrowserWallet, account, library, activate, active, deactivate} = useEthers();
    const [windowSize, setWindowSize] = React.useState(false);
    const [currentSupply, setCurrentSupply] = React.useState(0);
    const [mintState, setMintState] = React.useState(0);
    const [approveState, setApproveState] = React.useState(0);
    const [mutateState, setMutateState] = React.useState(0);
    const [maxSupply, setMaxSupply] = React.useState(4200);
    const [mintPrice, setMintPrice] = React.useState(0);
    const [mintPriceToSend, setMintPriceToSend] = React.useState(0);
    const [numToMint, setNumToMint] = React.useState(1);
    const [saleIsActive, setSaleIsActive] = React.useState(false);
    const [buttonText, setButtonText] = React.useState("Connect");
    const [mutateButtonText, setMutateButtonText] = React.useState("Mutate");
    const [approveButtonText, setApproveButtonText] = React.useState("Approve $OAPE");
    const [darkMode, setDarkMode] = React.useState(true);
    const [activeTab, setActiveTab] = React.useState('MINT');
    const [activeApe, setActiveApe] = React.useState(0);
    const [dropdown, setDropdown] = React.useState(false);
    const toggleOpen = () => setDropdown(!dropdown);

    //contract stuff
    const tokenCA = '0xf4dEAd672d2E3e16A3dCAeF4C2bA7Cb1b4D304Ff';
    const CA = testMode ? '0x810c9B83e685a79dA377AEcB1822a064057Fa568' : '0xCaB23D5Fb9a5d64F0755914C17e283Ff6563641d'; // test CA: 0x810c9B83e685a79dA377AEcB1822a064057Fa568 //real cA: 0xCaB23D5Fb9a5d64F0755914C17e283Ff6563641d
    const nftInterface = new Interface(NFTABI);
    const nftContract = new Contract(CA, NFTABI);
    const tokenContract = new Contract(tokenCA, TOKENABI);
    const tokenInterface = new Interface(TOKENABI);
    const getTotalSupply = { abi: nftInterface, address: CA, method: 'totalSupply', args: [] };
    const getMaxSupply = { abi: nftInterface, address: CA, method: 'maxSupply', args: [] };
    const getMintPrice = { abi: nftInterface, address: CA, method: 'mintFee', args: [] };
    const getSaleIsActive = { abi: nftInterface, address: CA, method: 'saleIsActive', args: [] };
    const getPresaleMode = {abi: nftInterface, address: CA, method: 'presaleMode', args: [] };
    const getPresaleReq = {abi: nftInterface, address: CA, method: 'PRESALE_TOKEN_REQ', args: [] };
    const getWhitelistStatus = {abi: nftInterface, address: CA, method: 'whitelistStatus', args: [account] };
    const getOapeAmount = {abi: nftInterface, address: CA, method: 'getTokenAmount', args: [account] };
    // const getYourApes = {abi: nftInterface, address: CA, method: 'tokensOfOwner', args: [account] };
    const [yourApes, setYourApes] = React.useState([]);
    const getTokenAllowance = {abi: tokenInterface, address: tokenCA, method: 'allowance', args: [account, CA]};
    const ownerCalls = [];
    for (let i = 1; i <= 666; i++) {
        ownerCalls.push({ abi: nftInterface, address: CA, method: 'ownerOf', args: [i.toString()] });
    }
    
    const oapeApprovalAmount = utils.parseEther("1000000000000000000000000");
    const mutationAmount = utils.parseEther("200");
    const yourOAPE = useTokenBalance(tokenCA, account);
    const burnedOAPE = useTokenBalance(tokenCA, '0x000000000000000000000000000000000000dEaD');

    //functions - mint
    const mintFunction = useContractFunction(
        nftContract,
        'mint',
        { transactionName: 'Mint' }
    );

    const mintToken = numTokens => {
        try {
            mintFunction.send(BigNumber.from(numTokens), {
                value: mintPriceToSend.toString()
            });
        } catch (error) {
            console.log('error while minting. or after minting. idk.');
            console.log(error ?? "");
        }
    };

    React.useEffect(() => {
        fetch(`https://graphql.moonbeanstoken.com/apeholders`, {
            method: 'GET'
        })
            .then(res => res.json())
            .then(response => {
                if (response?.[account] !== undefined) {
                    setYourApes(response?.[account].sort(function(a, b) {return a - b}));
                } else {
                    setYourApes([]);
                }
            })
            .catch(error => console.log(error));
    }, [account])

    //function - permit (rip, just saving for later)

    // const approveFunction = useContractFunction(
    //     tokenContract,
    //     'permit',
    //     { transactionName: 'Approve' }
    // );

    // async function approveOAPE() {
    //     const result = await signERC2612Permit(library, tokenCA, account, CA, oapeApprovalAmount);
    //     try {
    //         approveFunction.send(
    //             account, CA, oapeApprovalAmount, result.deadline, result.v, result.r, result.s
    //         );
    //     } catch (error) {
    //         console.log('couldn\'t approve');
    //         console.log(error ?? "");
    //     }
    // }

    //function -- approve
    const approveFunction = useContractFunction(
        tokenContract,
        'approve',
        { transactionName: 'Approve OAPE' }
    );

    const approveOAPE = () => {
        try {
            approveFunction.send(
                CA, oapeApprovalAmount
            );
        } catch (error) {
            console.log('couldn\'t approve');
            console.log(error ?? "");
        }
    }

    //function -- mutate
    const mutateFunction = useContractFunction(
        nftContract,
        'mutate',
        { transactionName: 'Mutate Ape' }
    );

    const mutateApe = apeID => {
        try {
            mutateFunction.send(
                BigNumber.from(apeID)
            );
        } catch (error) {
            console.log('couldn\'t mutate');
            console.log(error ?? "");
        }
    }

    const [rawTotalSupply, rawMaxSupply, rawMintPrice, rawSaleIsActive, rawPresaleMode, rawPresaleReq, 
        rawWhitelistStatus, rawOapeAmount, rawTokenAllowance] = useContractCalls([
        getTotalSupply,
        getMaxSupply,
        getMintPrice,
        getSaleIsActive,
        getPresaleMode,
        getPresaleReq,
        getWhitelistStatus,
        getOapeAmount,
        getTokenAllowance,
    ]);

    React.useEffect(() => {
        setCurrentSupply(BigNumber.from(rawTotalSupply?.[0] ?? 0).toString());
        setMaxSupply(BigNumber.from(rawMaxSupply?.[0] ?? 666).toString());
        setMintPrice(utils.formatEther(BigNumber.from(rawMintPrice?.[0] ?? 0)).toString());
        setMintPriceToSend(BigNumber.from(rawMintPrice?.[0] ?? 0) * numToMint);
        setSaleIsActive(rawSaleIsActive?.[0]);
    }, [rawTotalSupply, rawMaxSupply, rawMintPrice, rawSaleIsActive, rawPresaleMode, rawPresaleReq, rawWhitelistStatus, rawOapeAmount, numToMint])

    React.useEffect(() => {
        if (mintFunction.state.status !== undefined) {
            if (mintFunction.state.status === 'Mining') {
                setMintState(1);
            } else if (mintFunction.state.status === 'Success') {
                setMintState(2);
            } else if (mintFunction.state.status === 'Exception' && mintFunction.state.errorMessage?.includes('insufficient funds')) {
                setMintState(3);
            } else if (mintFunction.state.status === 'Exception' && mintFunction.state.errorMessage?.includes('User denied')) {
                setMintState(4);
            } else {
                setMintState(0);
            }
        }
        if (approveFunction.state.status !== undefined) {
            if (approveFunction.state.status === 'Mining') {
                setApproveState(1);
            } else if (approveFunction.state.status === 'Success') {
                setApproveState(2);
            } else if (approveFunction.state.status === 'Exception' && approveFunction.state.errorMessage?.includes('insufficient funds')) {
                setApproveState(3);
            } else if (approveFunction.state.status === 'Exception' && approveFunction.state.errorMessage?.includes('User denied')) {
                setApproveState(4);
            } else {
                setApproveState(0);
            }
        }
        if (mutateFunction.state.status !== undefined) {
            if (mutateFunction.state.status === 'Mining') {
                setMutateState(1);
            } else if (mutateFunction.state.status === 'Success') {
                setMutateState(2);
            } else if (mutateFunction.state.status === 'Exception' && mutateFunction.state.errorMessage?.includes('insufficient funds')) {
                setMutateState(3);
            } else if (mutateFunction.state.status === 'Exception' && mutateFunction.state.errorMessage?.includes('User denied')) {
                setMutateState(4);
            } else {
                setMutateState(0);
            }
        }
    }, [mintFunction.state, approveFunction.state, mutateFunction.state]);

    // function adjustedCountdownDate() {
    //     var targetTime = new Date(stopTime);
    //     targetTime.setMinutes(targetTime.getMinutes() - targetTime.getTimezoneOffset());
    //     return targetTime >= Date.now() ? targetTime : "";
    //   }

    async function connectAccount() {
        activateBrowserWallet();
    }

    async function disconnectAccount() {
        deactivate();
    }

    React.useEffect(() => {
        const updateWindowDimensions = () => {
            setWindowSize(window.innerWidth);
        };
        window.addEventListener("resize", updateWindowDimensions);
        return () => window.removeEventListener("resize", updateWindowDimensions)
    }, []);

    async function handleButton() {
        if (![42261, 42262].includes(chainId)) { 
            try {
                await library.provider.sendAsync({
                    method: 'wallet_switchEthereumChain',
                    params: [{chainId: '0xA516'}], //0x89
                });
            } catch (switchError) {
                console.log(switchError);
                if (switchError.code === 4902) {
                    try {
                        await library.providersendAsync({
                            method: 'wallet_addEthereumChain',
                            params: [{
                                chainId: '0xA516',
                                rpcUrl: 'https://emerald.oasis.dev',
                                chainName: 'Oasis Emerald',
                                nativeCurrency: {
                                    name: 'ROSE',
                                    symbol: 'ROSE', // 2-6 characters long
                                    decimals: 18,
                                },
                                blockExplorerUrl: 'https://explorer.emerald.oasis.dev/',
                            }]
                        });
                    } catch (addError) {
                        console.log(addError);
                    }
                }
                // handle other "switch" errors
            }
        } else {
            //front end checks to make up for emerald paratime issues
            if (saleIsActive && (Number(currentSupply) + numToMint <= Number(maxSupply))) {
                if (rawPresaleMode?.[0] && rawWhitelistStatus?.[0]) { 
                    mintToken(numToMint);
                } else if (!rawPresaleMode?.[0]) {
                    mintToken(numToMint);
                } else if (rawPresaleMode?.[0] && !rawWhitelistStatus?.[0]) {
                    setButtonText("Not Whitelisted.")
                } 
            }
        }
    }

    React.useEffect(() =>{
        if (account && (![42261, 42262].includes(chainId))) {
            if (windowSize < 600) {
                setButtonText("Wrong N/W");
            } else {
                setButtonText("Wrong Network");
            }
        } else if (account) {
            // if (saleIsActive && mintState === 0) {
            //     setButtonText("Mint");
            // } else if (saleIsActive && mintState === 1) {
            //     setButtonText("Minting...");
            // } else if (saleIsActive && mintState === 2) {
            //     setButtonText("Minted! Want Another?");
            // } else if (saleIsActive && mintState === 3) {
            //     setButtonText("Insufficient Funds. Try Again?")
            // } else if (saleIsActive && mintState === 4) {
            //     setButtonText("TX Rejected. Try Again?")
            // } else {
            //     setButtonText("Minting Soon!");
            // }
            setButtonText("Mint Complete - Trade now");

            if (approveState === 0) {
                setApproveButtonText("Approve $OAPE");
            } else if (approveState === 1) {
                setApproveButtonText("Approving...")
            } else if (approveState === 2) {
                setApproveButtonText("Approved")
            }
        } else {
            setButtonText("Connect");
        }
    }, [account, chainId, saleIsActive, mintState, approveState, active])

    React.useEffect(() => {
        if (account) {
            if (mutateState === 1) {
                setMutateButtonText("Mutating...");
            } else if (mutateState === 2) {
                setMutateButtonText("Mutation Successful! Mutate Back?");
            } else if (mutateState === 4) {
                setMutateButtonText("TX Rejected. Try Again?");
            }
        }
    }, [mutateState])

    React.useEffect(() => {
        if (activeApe !== 0) {
            setMutateButtonText(`Mutate Ape #${activeApe}`)
        }
    }, [activeApe])

    useEffect(() => {
        const interval = setInterval(() => {
          setShowTargetTime(targetTime >= Date.now());
        }, 1000);
        return () => clearInterval(interval);
      }, []);

    return (
        <div className="App">
            
            <Particles options={darkParticlesOptions}/>
            <header className={`App-header${darkMode ? '-dark' : ''}`}>

                {/*HEADER ROW*/}
                {account && <div className="account-connected">{shortenAddress(account)}</div>}
                <div className="top-left"><a className="homepage-link" href="https://oasisapes.club"><Image src={`/images/oasisapesbk.png`} className="top-left-logo" alt="logo" placeholderColor="#000000"/>APE HOMEPAGE</a></div>
                {showTargetTime && <div className="countdown-text gradient-text">
                    <Countdown date={targetTime}>
                        Mint is live!
                    </Countdown>
                </div>}
                {!showTargetTime && <div className="top-middle"><ButtonGroup aria-label="Basic example" className="mb--20">
                    <button onClick={() => setActiveTab("MINT")} className={`connect-wallet-button rainbow-dark rainbow-1 button-group-left ${activeTab === 'MINT' ? 'rainbow-selection' : ''}`}>MINT</button>
                    <button onClick={() => setActiveTab("MUTATE")} className={`connect-wallet-button rainbow-dark rainbow-1 button-group-right ${activeTab === 'MUTATE' ? 'rainbow-selection' : ''}`}>MUTATE</button>
                </ButtonGroup></div>}

                {/*MINTING DAPP*/}
                {activeTab === 'MINT' && <><div className="logo-wrapper" onClick={() => setDarkMode(true)}>
                    <Image src={`/images/oape.gif`} className="App-logo" alt="logo" placeholderColor="#000000"/>
                    </div>
                {(account || active) && <div className={`coming-soon-text${darkMode && '-dark'}`}><span className="bolder">{currentSupply} / {maxSupply}</span>&nbsp;&nbsp;minted!</div>}
                <span className="socials">
                <a href="https://discord.gg/dZAJM3p8h5"><div className="social-link"><FaDiscord color="white" size="40px"/></div></a>
                        {!account &&
                        <button className={`connect-wallet-button min-width-200 rainbow${darkMode ? '-dark' : ''} rainbow-1`}
                            onClick={connectAccount}>Connect Wallet</button>
                        }
                        {account &&<>
                            <button className={`connect-wallet-button min-width-200 rainbow${darkMode ? '-dark' : ''} rainbow-1`}>
                                <a className="no-text-dec" target="_blank" href="https://tofunft.com/collection/oasis-apes-club/items"><span className="theme-gradient ellipses" onClick={handleButton}>{buttonText}</span></a>
                            </button>
                            {/* {(account && saleIsActive && [42261, 42262].includes(chainId) && [0, 2, 4].includes(mintState)) && <div className="dropdown">
                                <button className="connect-wallet-button btn btn-outline-secondary dropdown-toggle rainbow-dark rainbow-1 mintnum-menu" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={toggleOpen}>
                                    {numToMint}
                                </button>
                                <div className={`dropdown-menu ${dropdown ? 'show' : ''}`} aria-labelledby="dropdownMenuButton">
                                    {[1, 2, 3, 4, 5].map(i => (
                                        <button key={`mintnum-${i}`}className="dropdown-item" onClick={() => {setNumToMint(i); toggleOpen()}}>{i}</button>
                                    ))}
                                </div>
                            </div>} */}
                            </>
                        }
                    <a href="https://twitter.com/apesoasis"><div className="social-link"><FaTwitter color="white" size="40px"/></div></a>
                </span>
                {account && <div className="main-text-wrapper"><div className={`coming-soon-text${darkMode && '-dark'}`}>
                    {/* Mint Price: <span className="bolder"><CountUp end={mintPrice}/>&nbsp;$ROSE</span>. 
                        {rawPresaleMode?.[0] && <span className="smaller-text">
                                <br/>Presale mode is active - you need &gt;1000 $OAPE tokens or a whitelisted address to mint.
                                <br/>Your whitelist status: <span className="bolder">{rawWhitelistStatus?.[0] ? "✅" : "❌"}</span>
                            </span>} */}
                        You have {yourApes.length} Oasis Ape NFT{yourApes.length !== 1 && 's'} in your wallet!
                    </div>
                </div>}</>}

                {/*MUTATION DAPP*/}
                {activeTab === "MUTATE" && <div className="container">
                    <div className="row">
                        <div className="panel mb--20">
                            <div className="panel-header">Your Apes ({(yourApes).length})</div>
                            <div className="panel-body">
                                {(yourApes).length === 0 && `No Apes Found.`}
                                {(yourApes).map((i, index) => (
                                    <div key={`apenum-${i}`}>
                                        <SingleApe i={(BigNumber.from(i ?? 0).toString())} CA={CA} setActiveApe={setActiveApe} activeApe={activeApe} mutateState={mutateState}/>
                                    </div>
                                ))}
                                {/* {allApes.map((i) => (
                                    <div key={`apenum-${i.toString}`}>
                                        <SingleApe i={i} CA={CA} setActiveApe={setActiveApe} activeApe={activeApe} mutateState={mutateState}/>
                                    </div>
                                ))} */}
                            </div>
                            <div className="row spread-em">
                                <span className="col-6 left-bottom-panel">Your $OAPE: {Number(utils.formatEther(BigNumber.from(yourOAPE ?? 0))).toFixed(2)}</span>
                                <span className="col-6 right-bottom-panel">🔥 burned $oape: {Number(utils.formatEther(BigNumber.from(burnedOAPE ?? 0))).toFixed(2)}</span>
                            </div>
                        </div>
                    </div>
                    {((yourApes).length !== 0) && <>
                        {BigNumber.from(rawTokenAllowance?.[0] ?? 0).lt(utils.parseEther("200000000000000000000")) && <div className="row left-right-padding">
                            <button onClick={() => approveOAPE()} className="connect-wallet-button rainbow-dark rainbow-1">{approveButtonText}</button>
                        </div>}
                        {BigNumber.from(rawTokenAllowance?.[0] ?? 0).gte(utils.parseEther("200000000000000000000")) && <div className="row left-right-padding">
                            <button onClick={ ((activeApe !== 0) && (BigNumber.from(yourOAPE ?? 0).gte(mutationAmount))) ? () => mutateApe(activeApe) : null} className={`connect-wallet-button ${(activeApe === 0 || BigNumber.from(yourOAPE ?? 0).lt(mutationAmount)) ? 'btn-disabled' : ''} rainbow-dark rainbow-1`}>{mutateButtonText}{mutateState !== 1 && <sup className="">&nbsp;&nbsp;&nbsp;(200 $OAPE)</sup>}</button>
                        </div>}
                    </>}
                </div>}

            </header>
        </div>
    );
}



// For a single row in the mutation/viewer
const SingleApe = ({i, CA, setActiveApe, activeApe, mutateState}) => {

    const nftInterface = new Interface(NFTABI);
    const [imageUrl, setImageUrl] = React.useState('');
    const [details, setDetails] = React.useState(null);
    const getURI = {abi: nftInterface, address: CA, method: 'tokenURI', args: [i] };
    const getMutationState = {abi: nftInterface, address: CA, method: 'mutatedTokens', args: [i] };
    const [rawURI, rawMutationState] = useContractCalls([getURI, getMutationState]);
    const [showPlaceholder, setShowPlaceholder] = React.useState(true);

    React.useEffect(() => {
        fetch(`${rawURI?.[0]}`, { method: 'GET' })
            .then(res => res.json())
            .then(response => {
                setImageUrl(response.image);
                setDetails(response.attributes);
            })
            .catch(error => console.log(error));
    }, [rawURI]);

    return (
        <>
            <div className="row mt--10 mb--10">
                <div className="col relative">
                    {showPlaceholder && <div className="centered-loader"><PacmanLoader color="white" size={17}/></div>}
                    <div className={`${(mutateState === 1 && activeApe === i) ? 'mutate-animation' : ''}`} onClick={mutateState !== 1 ? () => setActiveApe(i) : null}>
                        <Image 
                        src={imageUrl.replace('ipfs.io', 'moonbeans.mypinata.cloud')} 
                        className={`ape-selectooor ${activeApe === i ? 'ape-selected' : ''}`} 
                        alt="logo" 
                        noLazyLoad={true}
                        placeholderColor="#000000"
                        onLoad={() => setShowPlaceholder(false)}/>
                    </div>
                </div>
                <div className="col"><div className="white">Ape #{i}</div>
                    <div>
                        {(rawMutationState?.[0] ?? false) ? <div className="gradient-text">MUTATED</div> : <div>STANDARD</div>}
                    </div>
                    <div>
                        <button 
                            className={`connect-wallet-button rainbow-dark rainbow-1 ${mutateState === 1 ? 'btn-disabled' : ''} ${activeApe === i && 'rainbow-selection'}`} 
                            onClick={mutateState !== 1 ? () => setActiveApe(i) : null}>{activeApe === i ? `SELECTED` : `SELECT`}</button>
                    </div>
                </div>
                <div className="col left-align">
                    {details && Array.isArray(details) && details.map((trait, index) =>(
                        <div key={`trait-wrapper-${index}-${i}`} className="trait-wrapper">
                            {trait?.trait_type}:&nbsp;
                            <span className="gradient-text">{trait?.value}</span><br/>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export const CountdownObject = () => {

};

export default App;
